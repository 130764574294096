/*
  Theme Name: Triangle
  Theme Uri: http://www.themeum.com
  Author: Themeum
  Author Uri: http://www.themeum.com
  Description: Creative Site Template
  Version: 1.1
  */

  @import url(http://fonts.googleapis.com/css?family=Lato:100,300,400,700);
  @import url(http://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,400,300,600,700);
  @import url(http://fonts.googleapis.com/css?family=Noto+Sans:400,700);

  body {
    background: #fff;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    color: #000;
  }

  html {
    height: 100%;
  }


  a{
    color:#C03035;
  }

  a:hover {
    outline: none;
    text-decoration:none;
    color:#4fcccd;
  }

  a:focus {
    outline:none;
    outline-offset: 0;
  }

  a {
    -webkit-transition: 300ms;
    -moz-transition: 300ms;
    -o-transition: 300ms;
    transition: 300ms;
  }


  ul {
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Lato', sans-serif;
    font-weight: 300;
  }

  h1{
    color: #686868;
  }

  h2{
    font-size: 24px;
    color: #686868;
  }

  h3{
    font-size: 18px;
  }

  .overflow{
    overflow: hidden;
  }

  .uppercase{
    text-transform: uppercase;
  }

  .btn-common {
    font-size: 14px;
    color: #0099ae;
    border: 1px solid #0099ae;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    padding: 10px 25px;
  }

  #action .col-sm-5{
    position: absolute;

    top: 0;
    height: 100%;
    right: 0;
  }


  .btn-common:hover,
  .btn-common:focus{
    outline: none;
    background: none;
    box-shadow: none;
    color: #01707f;
    border-color:#01707f;
  }

  .align-right{
    text-align: right;
  }

  .inline{
    display: inline-block;
  }

  .padding{
    padding: 65px 0;
  }

  .padding-bottom{
    padding-bottom: 65px;
  }

  .padding-top{
    padding-top: 90px;
  }

  .padding-right{
    padding-right: 80px;
  }

  .padding-left{
    padding-left: 80px;
  }

  .margin-bottom{
    margin-bottom: 35px;
  }

     .carousel-indicators.visible-xs {
      height: 20px;
      margin: 0;
      padding: 0;
      position: absolute;
      top: -35px;
      width: 100%;
      left: 0;
    }

  .carousel-indicators.visible-xs li {
    border-color: #D29948;
    border-width: 2px;
    height: 12px;
    margin: 0 5px 0 0;
    width: 12px;
  }

  .carousel-indicators.visible-xs li.active {
    background: rgba(210, 153, 72, 0.7);
  }


/*************************
*******Header******
**************************/


#header{
  margin-bottom: 0;
  padding: 30px 0 60px 0;
}

#header .navbar-inverse .container{
  position: relative;
}

.search{
  position: absolute;
  top: 20px;
  right: 0;
  z-index: 1;
}

.search i{
  color: #00aeef;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 2px;
}

.field-toggle{
  position: relative;
  top: 30px;
  right: 15px;
  display: none;
  height: 50px;
}

.search-form{
  padding-left: 10px;
  height: 40px;
  font-size: 18px;
  color: #818285;
  font-weight: 300;
  outline: none;
  border: 1px solid #00aeef;
  margin-top: 20px;
  border-radius: 4px;
}

.social-icons ul li{
  padding: 0 10px;

}

.social-icons ul li:last-child{
  padding-right: 0;

}

.social-icons ul li a{
  font-size: 18px;
  color: #d3d3d3;
  padding: 0;
}

.social-icons ul li a:hover .fa-facebook {
  color:#0884d5;
}

.social-icons ul li a:hover .fa-twitter {
  color:#2AA7DC;
}

.social-icons ul li a:hover .fa-google-plus {
  color:#A22523;
}

.social-icons ul li a:hover .fa-dribbble {
  color:#E94989;
}

.social-icons ul li a:hover .fa-linkedin {
  color:#006DC0;
}

.social-icons ul li a:hover,
.social-icons ul li a:focus{
  background: none;
}

#header .navbar {
  background: #fff;
  border: 0;
  margin-bottom: 0;
}

#header .navbar-toggle{
  margin-top: 20px;
}

#header .navbar-brand{
  padding: 0;
  margin-left: 0;
}

#header .navbar-brand h1{
  padding: 0;
  margin: 0;
}

#header .navbar-nav.navbar-right >li:last-child{
  margin-right: 20px;
}

#header .navbar-nav.navbar-right >li a {
  color: #000;
  font-size: 12px;
  padding: 20px;
  text-transform: uppercase;
  font-weight: 300;
}


#header .navbar-inverse .navbar-nav  li.active > a,
#header .navbar-inverse .navbar-nav  li.active > a:focus,
#header .navbar-nav.navbar-right li > a:hover,
.navbar-inverse .navbar-nav > .open > a {
  background-color: inherit;
  border: 0;
  color: #00aeef;
}

#header .navbar-inverse .navbar-nav  li a:hover{
  color: #00aeef;
}


/*  Dropdown menu*/

ul.sub-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

#header .navbar-nav li ul.sub-menu li a{
  color: #818285;
  padding: 5px 0;
  font-size: 13px;
  display: block;
  text-transform: capitalize;
}

#header .navbar-nav li ul.sub-menu li .active{
  background: #00aeef;
  color: #fff;
  position: relative;
}

#header .navbar-nav li ul.sub-menu li .active i{
  position: absolute;
  font-size: 56px;
  top: -13px;
  color: #0884d5;
}

#header .navbar-nav li ul.sub-menu li .active .fa-angle-right{
  left: -3px;
}

#header .navbar-nav li ul.sub-menu li .active .fa-angle-left{
  right: -3px;
}

#header .navbar-nav li ul.sub-menu li a:hover,
#header .navbar-nav li ul.sub-menu li a:focus{
  background: #00aeef;
  color: #fff;
}

.fa-angle-down{
  padding-left: 5px;
}

.scaleIn {
  -webkit-animation-name: scaleIn;
  animation-name: scaleIn;
}

@-webkit-keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scaleIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}


/*************************
*******Footer******
**************************/

#footer {
  padding-bottom: 10px;
  background-image: url(../images/home/footer.png);
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: contain;
}

#footer h1{
  margin-bottom: 65px;
  margin-top: 36px;
}

#footer .bottom-separator {
  margin-bottom: 60px;
}

#message {
  height: 110px;
  resize: none;
}

.bottom > h2 {
  margin-top: 0;
  margin-bottom: 26px;
}

.bottom .media-body h3 {
  margin-top: 0;
  padding-left: 25px;
}

.bottom .media-body h3 a{
  color: #ea2c00;
}

.testimonial .media{
  margin-top: 0;
  margin-bottom: 25px;
}

.testimonial .media>.pull-left{
  margin-right: 25px;
}

.testimonial .media>.pull-left img{
  margin-top: 6px;
}

.testimonial .media .media-body blockquote{
  padding-left: 25px;
  padding-right: 30px;
  position: relative;
  border-left: 0;
  margin-bottom: 0;
}

.testimonial .media .media-body blockquote:before{
  position: absolute;
  left: 5px;
  top: 8px;
  content: '"';
  font-size: 40px;
  color: #ea2c00;
  font-family: 'Noto Sans', sans-serif;
  transform:rotate(180deg);
  -ms-transform:rotate(180deg); /* IE 9 */
  -webkit-transform: rotate(180deg); /* Opera, Chrome, and Safari */
  line-height: 0;
}

.testimonial .media .media-body blockquote:after{
  position: absolute;
  right: 30px;
  bottom: 14px;
  content: '"';
  font-size: 40px;
  color: #ea2c00;
  font-family: 'Noto Sans', sans-serif;
  line-height: 0;
}

.contact-info.bottom {
  padding-left: 25px;
}

.bottom > address {
  line-height: 22px;
  margin-bottom: 40px;
}

.form-control {
  border: 1px solid #eaeaea;
  font-weight: 300;
  outline: none;
  box-shadow: none;
  height: 40px;
}

.form-control:hover,
.form-control:focus{
  border-color: #4fcccd;
  outline: none;
  box-shadow: none;
}

.btn-submit {
  width: 100%;
  background-color: #4fcccd;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 5px;
  text-transform: uppercase;
  border-radius: 3px;
  margin-top: 5px;
  border-bottom: 3px solid #2f9697;
  box-shadow: none;
  padding: 10px;
}

.btn-submit:hover,
.btn-submit:focus{
  color: #fff;
  outline: none;
  box-shadow: none;
  opacity: .8;
}

.copyright-text{
  margin-top: 70px;
  color: #fff;
  font-size: 16px;
  padding-bottom: 15px;
}

.copyright-text a {
  color: #3e848a
}

.copyright-text p {
  margin-bottom: 0;
}

/*************************
*******Home Page******
**************************/
#home-slider {
  background: url(../images/home/slider-bg.png) 0 100% repeat-x;
  position: relative;
}

#home-slider .main-slider {
  position: relative;
  height: 450px;
}

#home-slider .slide-text{
  position: absolute;
  top: 50px;
  left: 0;
  width: 450px;
}

.animate-in .slide-text {
  -webkit-animation: fadeInLeftBig 700ms cubic-bezier(0.190,1.000,0.220,1.000) 200ms both;
  animation: fadeInLeftBig 700ms cubic-bezier(0.190,1.000,0.220,1.000) 200ms both;
}

#home-slider .slider-hill {
  position: absolute;
  right: 0;
  bottom: 0;
}

.animate-in .slider-hill {
  -webkit-animation: bounceInDown 1000ms ease-in-out 200ms both;
  animation: bounceInDown 1000ms ease-in-out 200ms both;
}

#home-slider .slider-house {
  position: absolute;
  right: 110px;
  bottom: -30px;
}

.animate-in .slider-house {
  -webkit-animation: bounceInDown 500ms ease-in-out 800ms both;
  animation: bounceInDown 500ms ease-in-out 800ms both;
}

#home-slider .slider-sun {
  position: absolute;
  right: 365px;
  bottom: 100px;
}

.animate-in .slider-sun {
  -webkit-animation: scaleIn 500ms ease-in-out 1200ms both;
  animation: scaleIn 500ms ease-in-out 1200ms both;
}

#home-slider .slider-birds1 {
  position: absolute;
  right: 470px;
  bottom: 75px;
}

.animate-in .slider-birds1 {
  -webkit-animation: fadeInLeft 500ms ease-in-out 1200ms both;
  animation: fadeInLeft 500ms ease-in-out 1200ms both;
}

#home-slider .slider-birds2 {
  position: absolute;
  right: 40px;
  bottom: 150px;
}

.animate-in .slider-birds2 {
  -webkit-animation: fadeInRight 500ms ease-in-out 1200ms both;
  animation: fadeInRight 500ms ease-in-out 1200ms both;
}


#home-slider h1{
  margin-top: 100px;
  margin-bottom: 25px;
}

#home-slider .btn-common {
  margin-top: 20px;
}

#home-slider .preloader {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -30px;
  right: 0;
  background: #fafafa;
  text-align: center;
}

#home-slider .preloader > i {
  font-size: 48px;
  height: 48px;
  line-height: 48px;
  color: #00aeef;
  position: absolute;
  left: 50%;
  margin-left: -24px;
  top: 50%;
  margin-top: -24px;
}

#action {
  background-image: url(../images/home/tour-bg.png);
  background-color: #fbfafa;
  background-repeat: repeat-x;
  height: 157px;
  background-position: center;
  display: table;
  position: static;
  width: 100%;
}

.vertical-center{
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  position: statice;
}


.single-service{
  overflow: hidden;
  display: block;
}

.single-service img {
  margin-bottom: 35px;
}

.single-service .fold {
  margin-bottom: 35px;
}

.single-service h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.single-service p {
  padding: 0 48px;
}

.action h1{
  margin-top: 0;
}

.action p{
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 300;
}

.action .btn-common{
  margin-top: 55px;
  float: left;
}

.title {
  color: #404040;
  font-weight: 300;
}

.single-features {
  overflow: hidden;
  padding-top: 65px;
  padding-bottom: 40px;
}

.single-features .col-sm-6 {
  margin-top: 50px;
}

.single-features:last-child {
  padding-bottom: 95px;
  padding-top: 0;
}

.single-features h2{
  margin-bottom: 15px;
}

#clients{
  padding-bottom: 45px;
}

.clients {
  margin-bottom: 45px;
}

.clients img {
  display: inline-block;
}

.clients-logo {
  overflow: hidden;
  margin-bottom: 10px;
}



/*************************
*******About Us Page******
**************************/

#action ,
#page-breadcrumb{
  position: relative;
}

#page-breadcrumb {
  background-image: url(../images/home/tour-bg.png);
  background-color: #fbfafa;
  background-repeat: repeat-x;
  height: 130px;
  background-position: center;
  display: table;
  position: static;
  width: 100%;
}

#team {
  padding: 85px 0;
}

#team-carousel {
  margin-top: 80px;
}

#company-information .padding-top{
  padding-top: 172px;

}

.single-service img {
  height: 85px;
}

.tour-button {
  background-image: url(../images/home/tour-icon2.png);
  height: 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  left: 0;
  background-position: 151px 4px;
}

.about-image {
  padding:90px 90px 0;
}

.team-single {
  position: relative;
}

.person-thumb {
  position: relative;
}

.person-thumb img {
}


.social-profile {
  background: none repeat scroll 0 0 rgba(137, 97, 46, 0.8);
  display: none;
  position: absolute;
  text-align: center;
  top: 0;
    -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;transition: all 0.9s ease 0s;
  width: 100%;
  height: 100%;
}

.team-single:hover .social-profile{
  background:rgba(112, 35, 64, 0.9);
  display: block;
  -webkit-animation: fadeInUp 400ms;
  animation: fadeInUp 400ms;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.social-profile .nav-pills {
  display: inline-block;
  margin-top: -26px;
  padding: 0;
  position: relative;
  top: 50%;
}

.social-profile .nav-pills li {
  display: inline-block;
  margin: 0 5px;
}

.social-profile .nav-pills li a {
  color: #fff;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  border-radius: 40px;
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.social-profile .nav-pills li a:hover {
  color: #702340;
  border-color: #702340;
}

.person-info h2 {
  font-weight: 300;
  margin-bottom: 5px;
}

.person-info h3 {
  font-size: 16px;
  color: #686868;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 0;
}

.team-carousel-control {
  position: absolute;
  top: 36%;
  width: 13px;
  height: 16px;
  text-indent: -9999999px;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.right.team-carousel-control {
  right: -50px;
  background-image: url(../images/aboutus/right.png);
}

.left.team-carousel-control {
  left: -50px;
  background-image: url(../images/aboutus/left.png);
}

.team-carousel-control:hover{
  background-position: 0 -18px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.count h1{
  font-size: 60px;
  color: #00aeef;
}

.count h3{
  font-size: 16px;
}

.progress .progress-bar.six-sec-ease-in-out {
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -ms-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition:  width 1s ease-in-out;
}

.top-zero{
  margin-top: 0;
}

h3.top-zero{
  font-size: 20px;
}

#company-information .about-us h2{
  margin-bottom: 45px;
}

.single-skill h3{
  font-size: 16px;
}

.progress{
  height: 30px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.progress-bar{
  line-height: 30px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.progress-bar.progress-bar-primary{
  background: #00aeef;
}



/*************************
*******Service Page******
**************************/
#recent-projects{
  padding: 85px 0;
}

.recent-projects .team-single{
  height: 355px;
}

.recent-projects p.padding-bottom{
  padding-bottom: 50px;
}

.choose{
  padding-top: 30px;
}

#company-information.choose .padding-top{
  padding-top: 50px;
}

#company-information h2{
  margin-bottom: 25px;
  margin-top: 0;
}

ul.elements{
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.elements li{
  margin: 6px 0;
}

ul.elements li > i{
  color:#C03035;
  display: inline-block;
  margin-right: 10px;
}

#company-information h1.margin-bottom{
  margin-bottom: 26px;
}

/*************************
*******Portfolio Default**
**************************/

.portfolio-single {
  position: relative;
}

.portfolio-thumb {
  position: relative;
}

.portfolio-thumb img {
  width: 100%;
}


.portfolio-single:hover .portfolio-view{
  display: block;
  -webkit-animation: fadeInUp 400ms;
  animation: fadeInUp 400ms;
}

#portfolio .row{
  margin-left: -10px;
  margin-right: -10px;
}

.portfolio-view {
  display: none;
  position: absolute;
  top: 0;
  height: 100%;
  background:rgba(112, 35, 64, 0.9);
  width: 100%;
  text-align: center;
}

.portfolio-view .nav-pills {
  padding: 0;
  display: inline-block;
  margin-top: -26px;
  top: 50%;
  position: relative;
}

.portfolio-view .nav-pills li {
  display: inline-block;
  margin: 0 5px;
}

.portfolio-view .nav-pills li a {
  color: #fff;
  border: 1px solid #fff;
  width: 40px;
  height: 40px;
  line-height: 38px;
  text-align: center;
  padding: 0;
  border-radius: 40px;
  -webkit-animation: scaleIn 400ms linear 100ms both;
  animation: scaleIn 400ms linear 100ms both;
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.portfolio-view .nav-pills li a:hover {
  color: #702340;
  border-color: #702340;
  -webkit-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.portfolio-info {
  overflow: hidden;
  margin-bottom: 30px;
}

.portfolio-info h2 {
  font-size: 18px;
  margin: 15px 0 0;
}

.portfolio-filter {
  margin: 80px 0;
  padding: 0;
}

.portfolio-filter li {
  display: inline-block;
  position: relative;
}

.portfolio-filter li a {
  border-radius: 0;
  color: #686868;
  font-size: 18px;
  font-weight: 300;
  padding: 0 25px;
  text-transform: capitalize;
  border: none;
}

.portfolio-filter li .active{
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.portfolio-filter li a:hover,
.portfolio-filter li a:focus,
.portfolio-filter li a.active {
  background: transparent;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.portfolio-filter li a.active:before{
  position: absolute;

  content: "";
  left: 0;
  width: 13px;
  height: 16px;
  background: url(../images/portfolio/icon.png);
  top: 5px;
}

.portfolio-pagination{
  text-align: center;

  padding-top: 40px;
  padding-bottom: 90px;
}

.pagination li{
  display: inline-block;
  margin: 0 10px;
}

.pagination li a{
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #fff;
}

.pagination li:first-child a{
  background: url(../images/portfolio/left.png);
  background-repeat: no-repeat;
  text-indent: -999999999px;
  background-position: center top 10px;
  width: 32px;
  height: 36px;
  border: none;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.pagination li:first-child a:hover{
  background: url(../images/portfolio/left.png);
  background-position: center top -37px;
  background-repeat: no-repeat;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.pagination li:last-child a{
  background: url(../images/portfolio/right.png);
  background-repeat: no-repeat;
  text-indent: -999999999px;
  background-position: center top 10px;
  width: 32px;
  height: 36px;
  border: none;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.pagination li:last-child a:hover{
  background: url(../images/portfolio/right.png);
  background-position: center top -37px;
  background-repeat: no-repeat;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

.pagination li a:hover,
.pagination .active a,
.pagination a:active,
.pagination .active a:hover,
.pagination .active a:focus,
.pagination a:focus {
  background: transparent;
  border-color: #C03035;
  color: #C03035;
}


/*****************************
Portfolio with right sidebar
*****************************/

.sidebar h3 {
  color: #404040;
  margin-top: 0;
  border-bottom: 3px solid #ececec;
  margin-bottom: 6px;
  padding-bottom: 8px;
}

.sidebar-item{
  margin-bottom: 48px;
}

.categories .navbar-stacked li {
  border-bottom: 1px solid #ececec;
}

.categories .navbar-stacked li:last-child {
  border-bottom: 0px solid #ececec;
}

.categories .navbar-stacked li a {
  font-size: 16px;
  color: #6a6a6a;
  padding: 8px 0;
  padding-left: 0;
}

.categories .navbar-stacked li a:hover{
  background: none;
  margin-left: 20px;
  color: #0099AE;
  opacity: 1;
}

.categories .navbar-stacked li.active a{
  color: #0099AE;
  padding-left: 20px;
}

.categories .navbar-stacked li.active a:before{
  position: absolute;
  content: "";
  border-color: transparent transparent transparent #0099AE;
  border-width: 8px;
  border-style: solid;
  left: 0;
  top: 13px;
  margin-top: 0;
  z-index: 0;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.categories .navbar-stacked li.active a:after {
  position: absolute;
  content: "";
  border-color: transparent transparent transparent #fff;
  border-width: 6px;
  border-style: solid;
  left: 0px;
  top: 15px;
  margin-top: 0;
  z-index: 1;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.sidebar-item .media{
  border-bottom: 1px solid #ececec;
  padding-bottom: 10px;
  padding-top: 21px;
  margin-top: 0;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.sidebar-item .media:last-child{
  border-bottom: 0px solid #ececec;
  padding-bottom: 0;
}

.sidebar-item .media:hover{
  border-left: 2px solid #0099AE;
  padding-left: 5px;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.sidebar-item .media:hover.media .media-body h4 a{
  color: #0099AE;
  transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
}

.sidebar-item .media .media-body h4 {
  margin-top: 0;
  margin-bottom: 8px;
}

.sidebar-item .media .media-body h4 a{
  color: #7d7d7d;
  font-size: 16px;
}

.sidebar-item .media .media-body p {
  color: #b4b4b4;
  font-size: 12px;
}

.tag-cloud .nav-pills{
  margin-top: 16px;
}

.tag-cloud .nav-pills li{
  margin: 0;
  margin-top: 6px;
  margin-right: 4px;
}

.tag-cloud .nav-pills li a{
  font-size: 14px;
  font-weight: 300;
  padding: 5px 15px;
  background: #bbbbbb;
  color: #fff;
  border-radius: 3px;
}

.tag-cloud .nav-pills li a:hover{
  background: #0099AE;
}

.popular ul.gallery {
  margin: 0;
  padding: 0;
  list-style: none;
  margin: 10px -8px;
}

.popular ul.gallery li {
  display: block;
  width: 33.33%;
  float: left;
  padding: 8px;
}

.popular ul.gallery li a {
  display: block;
}

.popular ul.gallery li a img {
  width: 100%;
  transition: border-radius 300ms ease-in;
  -webkit-transition: border-radius 300ms ease-in;
}

.popular ul.gallery li a img:hover {
  border-radius: 60px;
}


/* Start: Recommended Isotope styles */

/**** Isotope Filtering ****/
.isotope-item {
  z-index: 2;
}

.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

/**** Isotope CSS3 transitions ****/

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -ms-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  -ms-transition-property: height, width;
  -o-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -ms-transition-property: -ms-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
}

/**** disabling Isotope CSS3 transitions ****/
.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;
  -moz-transition-duration: 0s;
  -ms-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}




/*****************************
Portfolio Details
*****************************/

#portfolio-information.padding-top{
  padding-top: 100px;
}

.project-name h2{
  margin-top: 0;
  margin-bottom: 15px;
}

#related-work .title{
  margin-bottom: 55px;
  margin-top: 10px;
}

.navbar-default {
  background: none;
  padding: 0;
}

.navbar-default li a{
  font-size: 12px;
  padding: 0;
  padding-right: 15px;
}

.navbar-default li a i{
  padding-right: 8px;
}

.navbar-default li a:hover{
  color: #0884d5;
  background: none;
}

.skills .navbar-default,
.client .navbar-default{
  margin-left: 35px;

}

.live-preview .btn-common{
  margin-top: 21px;
  padding: 13px 45px;
}


/*****************************
Blog Default
*****************************/

.single-blog.timeline {
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  border-bottom: 0;
  position: relative;
  padding-bottom: 0;
}

.timeline .post-content {
  padding: 20px 20px 0;
}

.post-thumb{
  margin: -1px -1px 0;
  position: relative;
  overflow: hidden;
}

.timeline .post-thumb img {
  width: 100%;
}

.post-thumb iframe{
  width: 100%;
  min-height: 270px;
  border: 0;
}

.post-overlay{
  position: absolute;
  top: 50%;
  display: none;
  width: 94px;
  height: 140px;
  margin-top: -70px;
  overflow: hidden;
}

.post-overlay span{
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-left: -60px;
  -webkit-animation: fadeInUp 400ms;
  animation: fadeInUp 400ms;
  z-index: 9;
}


.post-overlay span:before{
  position: absolute;
  bottom: 0;
  border-style: solid;
  border-width: 60px;
  content: "";
  border-color: transparent transparent #0884d5 transparent;
  z-index: -1;
  cursor: initial;
}

.post-overlay span a{
  font-size: 30px;
  color: #fff;
  background: transparent;
  padding: 0;
  margin-left: 50px;
}

.single-blog:hover .post-overlay{
  display: block;
  -webkit-animation: fadeIn 300ms;
  animation: fadeIn 300ms;
}

.timeline-divider{
  position: relative;
  padding-top: 50px;
}

.timeline-divider:before{
  position: absolute;
  top: 0;
  content: "";
  border-left-style: solid;
  border-left-width: 1px;
  height: 100%;
  border-left-color: #0099AE;
  left: 50%;
  margin-left: -2px;
}

.timeline-blog .col-sm-6.padding-top{
  padding-top: 70px;
}

.post-content{
  padding: 20px 0;
}

.timeline-date .btn-common{
  font-size: 18px;
  color: #4a4a4a;
  font-weight: 300;
  border-radius: 0;
  padding: 17px 40px;
}

.arrow-right{
  position: relative;
}

.arrow-right:after{
  position: absolute;
  right: 1px;
  content: "";
  top: 15px;
  background: url(../images/blog/left.png);
  width: 43px;
  height: 54px;
}

.arrow-left{
  position: relative;
}

.arrow-left:before{
  position: absolute;
  left: -2px;
  content: "";
  top: 86px;
  background: url(../images/blog/right.png);
  width: 43px;
  height: 54px;
}

.post-title {
  margin-top: 0;
}

.post-title a,
.comments-number a{
  color: #686868;
}

.post-content .post-author{
  margin-top: 0;

}

.post-content  .post-author a{
  font-weight: 300;
  font-size: 14px;
  color: #0099AE;
}

a.read-more{
  color: #0099AE;
  font-weight: 300;
}

a.read-more:hover{
  color: #01707F
}

.post-bottom{
  border-top: 1px solid #eeeeee;
  padding-top: 10px;
  margin-top: 20px;
  margin: 20px -20px 0;
  padding: 20px 20px 0;
}


/*****************************
Blog with right sidebar
*****************************/

.single-blog{
  padding-bottom: 30px;
}

.single-blog .post-bottom{
  border-bottom: 1px solid #eeeeee;
  padding-top: 10px;
  padding-bottom: 10px;
}

.single-blog .post-overlay span{
  left: 0;
  top: 0;
  margin-left: 0;
  width: 94px;
  height: 140px;
  background: url(../images/blog/blog-arrow.png);
  -webkit-animation: fadeInLeft 400ms;
  animation: fadeInLeft 400ms;
}

.single-blog .post-overlay span a{
  margin-left: 8px;
  font-weight: 700;
  font-size: 36px;
  line-height: 18px;
  position: absolute;
  top: 50px;
}

.single-blog .post-overlay span a small{
  font-size: 16px;
  font-weight: 300;
  margin-left: 5px;
}

.single-blog .post-overlay span:before{
  border-color: transparent;
  border-width: 0;
}

.post-nav{
  margin:0;
  padding: 0;
}

.post-nav li a{
  color: #0099AE;
  padding: 0;
  text-align: left;
}

.post-nav li a i{
  color: #0099AE;
  margin-right: 8px;
}

.post-nav li a:hover{
  background: none;
}

.blog-padding-right{
  padding-right:35px;
}

.blog-pagination {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 55px;
}

/*****************************
Blog with right sidebar
*****************************/

.masonery_area .single-blog{}
.masonery_area .single-blog .post-thumb{
  height: auto;
}

.masonery_area .single-blog .post-title{
  margin-top: 5px;
}

.masonery_area .single-blog .post-title a{
  font-size: 20px;
}

.masonery_area .single-blog .post-thumb img{
  height:auto;
}

.masonery_area .single-blog .post-bottom{
  border-top:0;
  padding-top:0;
  margin-top:15px;
}

/* Audio CSS */

.audiojs{
  background:#C03035;
  height: 45px;
  width: 100%;
}

.audiojs .scrubber{
  background: #fff;
  height: 8px;
  border-top: 0;
  width: 170px;
  margin-top: 19px;
  margin-left: 12px;
  border-radius: 10px;
}

.audiojs .play-pause{
  background: #a92b2f;
  width: 68px;
  height: 45px;
  padding: 0;
  padding-left: 24px;
  padding-top: 10px;
  border-right: 0;
}

.audiojs .time{
  display: none;

}

.audiojs .progress{
  background: #A92B2F;

  height: 8px;
  border-radius: 10px;
}

.audiojs .loaded{
  background:#fff;
  height: 8px;
  border-radius: 10px;
}


/*****************************
Blog Details
*****************************/

.navbar-nav.post-nav li{
  margin-right: 60px;
}

.single-blog.blog-details .post-content{
  padding-top: 30px;
}

.single-blog.blog-details .post-bottom{
  margin-top: 55px;
}

.blog-share{
  margin-top: 40px;
  background: #f5f5f5;
  display: inline-block;
  padding: 2px 0;
}

span.stMainServices,
span.stButton_gradient,
.stButton .chicklets{
  height:24px !important;
}

.author-profile.padding{
  padding-top:50px;
}

.author-profile .col-sm-2 img{
  width: 100%;
  border-left: 3px solid #0099AE;
}

.author-profile h3{
  margin-top:0;
  color: #3a424c;
}

.author-profile p{
  color: #3a424c;
  font-weight: 400;
}

.author-profile span a{
  color: #C03035;
}

.response-area{
  border-bottom: 0;
}

.response-area h2{
  margin-top: 0;
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 20px;
}

.post-comment{
  padding-left: 70px;
  padding-top: 36px;
  border-top: 1px solid #f1e8dd;
}

.post-comment .pull-left img{
  margin-right: 60px;
  border-left: 3px solid #0884d5;
}

.post-comment .media-body{
  padding-top: 6px;
  border-bottom: 0;
  padding-bottom: 50px;
}

.post-comment .media-body p{
  margin-top:10px;
}

.post-comment .media-body span i{
  color: #C03035;
  margin-right: 10px;
}

.post-comment .media-body span a{
  color: #C03035;
  font-weight: 700;
}

.post-comment .media-body .post-nav li a{
  font-weight: 300;
}

.parrent .media-list{
  margin-left: 190px;
}

.parrent .post-comment{
  padding-left: 0;
  margin-left: 70px;
}


/****************************
********* Contact Us*********
*****************************/
#map-section {
  margin-top: 50px;
  margin-bottom: 50px;
  position: relative;
}

#gmap {
  height: 350px;
}

.get-in-touch img {
  margin-top: 35px;
  margin-bottom: 32px;
}

.get-in-touch p {
  font-size: 18px;
  font-weight: 300;
}

#map-section .contact-info {
  position: absolute;
  background-color: #7c3651;
  right: 0;
  top: 0;
  width: 33%;
  height: 100%;
  padding:25px 50px;
  opacity: 0.9;
  color: #fff;
}

#map-section .contact-info h2,
#map-section .contact-info a {
  color: #fff
}

#map-section address a:hover {
  color:#00AEEF
}

#contact-form #message{
  resize:none;
}

#contact-form .btn-submit {
  width: 28%;
  margin: 5px auto;
}

.corporate-info .corporate-address {
  background-image: url("../images/contact-bg.png");
  background-position: left top;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-bottom: 50px;
}

.corporate-info .corporate-address address {
  background-image: url("../images/icon-map.png");
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 40px;
}


/****************************
****** Price-table CSS*******
*****************************/

.price-table {
  margin-top: 88px;
  margin-bottom: 96px;
}

.single-price ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.table-heading {
  background-color: transparent !important;
  color: #FFFFFF !important;
  overflow: hidden;
  padding: 0 !important;
  text-align: center;
}

.single-price.price-one .plan-name,
.single-price.price-two .plan-name,
.single-price.price-three .plan-name,
.single-price.price-four .plan-name {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
  padding: 10px 0;
  text-transform: capitalize;
}

.single-price.price-one .plan-name {
  background-color: #0e76bc;
}

.single-price.price-two .plan-name {
  background-color: red;
}

.single-price.price-three .plan-name {
  background-color: red;
}

.single-price.price-four .plan-name {
  background-color: #6c825f;
}


.single-price .plan-price {
  color: #fff;
  float: none;
  font-size: 14px;
  height: 110px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  z-index: 5;
  padding-top: 15px;
}


.plan-price .dollar-sign {
  font-size: 18px;
}

.plan-price .price {
  font-size: 24px;
}

.plan-price .month {
  display: block;
  margin-top: -6px;
}

.price-one .plan-price:after,
.price-two .plan-price:after,
.price-three .plan-price:after,
.price-four .plan-price:after  {
  border-style: solid;
  border-width: 110px;
  content: "";
  height: 0;
  left:50%;
  position: absolute;
  top: 0;
  width: 0;
  z-index: -1;
  margin-left: -110px;
}

.price-one .plan-price:after {
  border-color: #0d6fb1 transparent transparent;
}

.price-two .plan-price:after {
  border-color: #ac2429 transparent transparent;
}

.price-three .plan-price:after {
  border-color: red transparent transparent;
}

.price-four .plan-price:after {
  border-color: #5f7452 transparent transparent;
}

.single-price ul li,
.single-table ul li {
  padding: 12px 18px;
  background-color: #f7f7f7;
  margin-bottom: 5px;
  color: #696969;
}

.single-price ul li span,
.single-table ul li span {
  float: right;
}

.price-one .btn-buynow,
.price-two .btn-buynow,
.price-three .btn-buynow,
.price-four .btn-buynow   {
  border-radius: 0;
  color: #fff;
  padding: 12px;
  font-weight: 300;
  letter-spacing: 3px;
  text-transform: uppercase;
  display: block;
}

.price-one .btn-buynow  {
  background-color: #0d6fb1;
}

.price-two .btn-buynow  {
  background-color: #ac2429;
}

.price-three .btn-buynow  {
  background-color: #e78817;
}

.price-four .btn-buynow  {
  background-color: #5f7452;
}

.price-one .btn-buynow:hover  {
  background-color: #0a5a90;
}

.price-two .btn-buynow:hover  {
  background-color: #821b1e;
}

.price-three .btn-buynow:hover  {
  background-color: #c57413;
}

.price-four .btn-buynow:hover  {
  background-color: #48583e;
}


.price-table2, .price-table3{
  margin-bottom:125px;
}

.price-table4 {
  margin-bottom: 150px
}

.table-one .table-header,
.table-two .table-header,
.table-three .table-header,
.table-four .table-header {
  overflow: hidden;
  padding:20px;
  color: #fff;
}


.table-one .table-header {
  background-color:#0e76bc;
}

.table-two .table-header {
  background-color:#c03035;
}

.table-three .table-header {
  background-color:#ed8d1b;
}

.table-four .table-header {
  background-color:#6c825f;
}

.single-table {
  position: relative;
  padding-top: 60px;
}

.table-one:before,
.table-two:before,
.table-three:before,
.table-four:before {

  border-style: solid;
  border-width: 0 130px 60px;
  content: "";
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.table-one:before {
  border-color: transparent transparent #0d6fb1;
}

.table-two:before {
  border-color: transparent transparent #ac2429;
}

.table-three:before {
  border-color: transparent transparent #e78817;
}

.table-four:before {
  border-color: transparent transparent #5f7452;
}

.table-header h2 {
  color: #fff;
  float: left;
  font-size: 24px;
  margin-top: 7px;
}

.table-header .plan-price {
  float: right;
  margin: 0;
  overflow: hidden;
  padding-top: 1px;
}

.single-table .btn-signup {
  display: block;
  text-align: center;
  position:relative;
  z-index:1;
}

.single-table ul {
  margin: 0;
  padding: 0;
}

.table-one .btn-signup:after,
.table-two .btn-signup:after,
.table-three .btn-signup:after,
.table-four .btn-signup:after {
  content: "";
  position: absolute;
  border-style: solid;
  border-width: 78px 130px 0;
  height: 0;
  left: 0;
  top: -1px;
  width: 100%;
  z-index: -1;
}

.table-one .btn-signup:after {
  border-color: #0d6fb1 transparent transparent;
}

.table-two .btn-signup:after {
  border-color: #ac2429 transparent transparent;
}

.table-three .btn-signup:after {
  border-color: #e78817 transparent transparent;
}

.table-four .btn-signup:after {
  border-color: #5f7452 transparent transparent;
}

.single-table .btn-signup a {
  color: #fff;
  display: inline-block;
  overflow: hidden;
  padding: 20px 0 0 0;
  text-transform: uppercase;
  letter-spacing: 3px;
}


.table-one .btn-signup:hover.btn-signup:after{
 border-color: #0b598d transparent transparent;
}


.table-two .btn-signup.btn-signup:hover:after {
  border-color: #831c20 transparent transparent;
}

.table-three .btn-signup.btn-signup:hover:after {
 border-color: #bd7014 transparent transparent;
}

.table-four .btn-signup.btn-signup:hover:after{
  border-color: #3d4b35 transparent transparent;
}


/*************************
********404 page CSS******
**************************/

#error-page {
  display: table;
  height: 100%;
  min-height: 100%;
  width: 100%;
  color: #686868;
  font-size: 18px;
  font-weight: 300;
  padding: 100px 0;
}

#error-page .container-fluid {
  overflow: hidden;
}

.error-page-inner {
  display: table-cell;
  vertical-align: middle;
}

.bg-404 {
  background-image: url("../images/home/tour-bg.png");
  background-repeat: repeat-x;
  background-position: center top;
}

.error-image {
  display: inline-block;
  padding-top: 50px;
  padding-bottom: 50px;
}

.error-image img {
  width: 100%;
}

#error-page h2 {
  font-size:36px;
  text-transform: uppercase;
}

.btn-error {
  font-weight: 300;
  margin-top: 30px;
  padding:25px 55px;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px;
  background-color: transparent;
  border: 1px solid #cc9b59;
  margin-top: 50px;
  margin-bottom: 50px;
}

.btn-error:hover, .btn-error:focus  {
  background-color: #cc9b59;
  border-color:#cc9b59;
  color:#fff;
}

#error-page i, #coming-soon i {
  border: 1px solid #cc9b59;
  height: 40px;
  width: 40px;
  line-height: 40px;
  color: #cc9b59;
  font-size: 17px;
  margin:0 15px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#error-page i:hover, #coming-soon i:hover {
  background-color: #cc9b59;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}


/*************************
********404 page CSS******
**************************/

#error-page {
  display: table;
  height: 100%;
  min-height: 100%;
  width: 100%;
  color: #686868;
  font-size: 18px;
  font-weight: 300;
  padding: 100px 0;
}

#error-page .container-fluid {
  overflow: hidden;
}

.error-page-inner {
  display: table-cell;
  vertical-align: middle;
}

.bg-404 {
  background-image: url("../images/404-bg.png");
  background-repeat: repeat-x;
  background-position: center top;
}

.error-image {
  display: inline-block;
  padding-top: 50px;
  padding-bottom: 50px;
}

.error-image img {
  width: 100%;
}

#error-page h2 {
  font-size:36px;
  text-transform: uppercase;
}

.btn-error {
  background-color: rgba(0, 0, 0, 0);
  border: 3px solid #4FCCCD;
  color: #797979;
  font-size: 16px;
  font-weight: 700;
  letter-spacing:0;
  margin-bottom: 50px;
  margin-top: 50px;
  padding: 25px 53px;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
}

.btn-error:hover, .btn-error:focus  {
  background-color: #4FCCCD;
  border-color:#4FCCCD;
  color:#fff;
}

#error-page i, #coming-soon i {
  border: 3px solid #cecece;
  height: 40px;
  width: 40px;
  line-height: 35px;
  color: #cecece;
  font-size: 17px;
  margin:0 15px;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#error-page i.fa-twitter:hover,
#coming-soon i.fa-twitter:hover,
#error-page i.fa-twitter:hover,
#coming-soon i.fa-twitter:hover,
#error-page i.fa-google-plus:hover,
#coming-soon i.fa-google-plus:hover {
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

#error-page i.fa-facebook:hover,
#coming-soon i.fa-facebook:hover{
  background-color:transparent;
  color: #314A87;
  border-color:#314A87;
}

#error-page i.fa-twitter:hover,
#coming-soon i.fa-twitter:hover{
  background-color:transparent;
  color: #22BBF4;
  border-color:#22BBF4;
}

#error-page i.fa-google-plus:hover,
#coming-soon i.fa-google-plus:hover{
  background-color:transparent;
  color: #DD4C3B;
  border-color:#DD4C3B;
}

/*************************
******Coming Soon CSS*****
**************************/

.logo-image {
  display: block;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 95px;
}

.logo-image img {
  display: inline-block;
}

#coming-soon {
  background-image: url("../images/coming-soon-bg.png");
  background-position: center top;
  background-repeat: repeat-x;
  padding: 40px 0;
  overflow: hidden;
}

#coming-soon .social-link {
  margin-top: 35px;
}

.time-count {
  display: block;
  text-align: center;
}

.time-count ul {
  padding-left: 0;
}

#countdown {
  display: block;
  max-width: 960px;
  margin: 80px auto 100px;
}


#countdown li {
  float: left;
  display: block;
  width: 25%;
  text-align: center;
  height: 254px;
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: contain !important;
  position: relative;
  color: #fff;
}

#countdown li.angle-one {
  background-image: url(../images/coming-soon1.png);
  left: 120px;
}

#countdown li.angle-two {
  background-image: url(../images/coming-soon2.png);
  left: 40px;
}

#countdown li.angle-three {
  background-image: url(../images/coming-soon3.png);
  right: 40px;
}

#countdown li.angle-four {
  background-image: url(../images/coming-soon4.png);
  right: 120px;
}

#countdown li > span {
  font-size: 48px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  display: inline-block;
  margin-top: 40px;
}

#countdown li.angle-two > span,
#countdown li.angle-four > span {
  margin-top: 80px;
}


#subscribe {
  background-color: #565656;
  color: #FFFFFF;
  padding: 40px 0 50px;
}

#subscribe h2 {
  color: #fff;
  text-transform: uppercase;
}

#subscribe .fa-envelope-o {
  color: #0884d5;
  margin-right: 5px;
}

#subscribe .form-control {
  background-color: transparent;
  height: 52px;
  color: #fff;
  border:2px solid #00aeef;
}

#newsletter {
  margin-top: 35px;
  position: relative;
}

#newsletter i {
  color: #0884d5;
  font-size: 36px;
  position: absolute;
  right: 12px;
  top: 7px;
}

.newsletter p {
  color: #919191;
  font-size: 12px;
  margin-top: 10px;
}

#coming-soon-footer {
  padding: 45px 0;
  line-height: 15px;
  overflow: hidden;
}

#coming-soon-footer a {
  color: #d89b4e;
}

#coming-soon-footer a:hover {
  color: #b38040
}

/*Shortcodes CSS*/
.bs-example{
  margin: 0 0 20px;
}

.panel-group{
  border: 1px solid #eee;
}
.panel-default{
  border: 0;
}
.panel-group .panel{
  border-radius: 0;
}
.panel-group .panel+.panel{
  margin-top: 0;
}

.panel-default>.panel-heading{
  background-color: transparent;
  border-top: 1px solid #eee;
}

.panel-group .panel-default:first-child>.panel-heading{
  border-top: 0;
}

.panel-default>.panel-heading+.panel-collapse .panel-body{
  border-top-color: #eee;
}


.nav-pills>li.active>a, .nav-pills>li.active>a:hover, .nav-pills>li.active>a:focus{
  background-color: #4FCCCD;
}

.tab-content {
  padding-top: 20px;
}

/*Features*/
.feature-inner{
  text-align: center;
}
.icon-wrapper{
  display: inline-block;
  background-color: #4FCCCD;
  color: #fff;
  padding: 25px;
  box-shadow: 0 0 0 5px rgba(0,0,0,.1) inset;
  -webkit-box-shadow: 0 0 0 5px rgba(0,0,0,.1) inset;
  border-radius: 100%;
  margin: 10px 0;
}
