@media only screen and (min-width : 768px) {
  .nav.navbar-nav > li:hover > ul.sub-menu{
    display: block;
    -webkit-animation: fadeInUp 400ms;
    animation: fadeInUp 400ms;
  }

  ul.sub-menu {
    position: absolute;
    top: 60px;
    left: 0;
    background: #fff;
    padding: 10px 0;
    width: 240px;
    box-shadow: none;
    display: none;
    z-index: 999;
    border: 1px solid #00aeef;
  }

  #header .navbar-nav li ul.sub-menu li a {
    padding-left: 20px;
  }


}

/*md only*/
@media (min-width: 992px) and (max-width: 1199px) {
  .col-md-4.portfolio-item{
    width: 33%;
  }
}

/*sm only*/
@media (min-width: 768px) and (max-width: 991px) {
  .col-sm-4.portfolio-item{
    width: 33%;
  }
}

/* md */
@media only screen and (max-width : 1200px) {

  /*Pricing Table*/
  .single-price .plan-price:after {
    border-width:100px;
    margin-left:-100px;
  }

  .single-table .table-content {
    padding: 20px;
  }

  .table-header {
    padding: 0 10px 7px;
  }

  .single-table:before {
    border-width:0 106px 60px;
  }

  .single-table .btn-signup:after {
    border-width:78px 106px 0;
  }

}

/* sm */
@media only screen and (max-width : 992px) {

  h1{
    font-size: 25px;
  }

  #header .navbar-nav.navbar-right li {
    padding: 0;
  }

  #header .navbar-nav.navbar-right >li a {
    padding: 20px 10px;
  }

  #home-slider {
    margin-top: 30px;
  }

  #home-slider .main-slider {
    height: 250px;
  }

  #home-slider .slide-text {
    top: 0;
    width: 50%;
  }

  #home-slider h1 {
    margin-top: 0;
  }

  #home-slider .slider-hill {
    width: 400px;
  }

  #home-slider .slider-house {
    width: 250px;
    right: 90px;
    bottom: -20px;
  }

  #home-slider .slider-sun {
    right: 255px;
    bottom: 65px;
    width: 60px
  }

  #home-slider .slider-birds1 {
    right: 320px;
    bottom: 60px;
    width: 70px;
  }

  #home-slider .slider-birds2 {
    right: 0;
    bottom: 90px;
    width: 80px;
  }

  /*Pricing Table*/
  .single-price {
    margin-bottom: 40px;
  }

  .single-price .plan-price {
    height: 140px;
  }

  .single-price .plan-price:after {
    border-width: 140px;
    margin-left: -140px;
  }

  .single-table {
    margin-bottom: 50px;
  }

  .single-table:before {
    border-width: 0 172px 60px;
  }

  .single-table .btn-signup:after {
    border-width: 78px 173px 0;
  }

  #price-table2 {
    margin-bottom: 100px;
  }

  .nav.navbar-nav.post-nav li{
    display: inline-block;
    margin-right: 15px;
  }

  .masonery_area .nav.nav-justified.post-nav li {
    display: table-cell;
  }

  /* Blog */
  .post-comment {
    padding-left: 0;
  }

  .post-comment .pull-left img {
    margin-right: 20px;
  }

  .parrent .post-comment {
    margin-left: 0;
  }

  .parrent .media-list {
    margin-left: 150px;
  }

  .single-blog.two-column .post-overlay{
    margin-top: 0;
  }

}


/* xs */
@media only screen and (max-width : 768px) {

  h1{
    font-size: 22px;
  }

  h2{
    font-size:20px;
  }

  .padding{
    padding: 30px 0;
  }

  #header {
    padding-bottom: 40px;
    padding-top: 10px;
  }

  .social-icons.pull-right{
    float: left !important;
  }

  #header .navbar-inverse .navbar-toggle,
  #header .navbar-inverse .navbar-toggle:focus {
    border-color: #00aeef;
    margin-top: 15px;
    margin-right: 5px;
    outline: none;
  }

  .navbar-header .navbar-toggle .icon-bar {
    background-color: #00aeef;
  }

  #header .navbar-inverse .navbar-toggle:hover {
    background: #00aeef;
  }

  #header .navbar-inverse .navbar-toggle:hover .icon-bar {
    background: #fff;
  }
 
  .navbar-inverse .navbar-collapse,
  .navbar-inverse .navbar-form {
    border-top: 0;
  }

  .navbar.navbar-inverse {
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .social-icons ul li:first-child{
    padding-left: 0;
  }

  .search {
    top: -40px;
  }

  #header .navbar-nav.navbar-right li > a > i {
    display: inline-block;
    float: right;
    padding: 8px 10px 8px 30px
  }

  #header .navbar-nav.navbar-right li > a {
    padding: 10px 0;
  }

  #header .navbar-nav li ul.sub-menu li a {
    padding: 5px 10px;
  }

  #header .navbar-inverse .navbar-nav li.active > a, 
  #header .navbar-inverse .navbar-nav li.active > a:focus, 
  #header .navbar-nav.navbar-right li > a:hover {
    border: none;
  }


  #home-slider {
    margin: 50px 20px;
  }

  #home-slider .main-slider {
    height: 500px;
  }

  #home-slider .slide-text {
    top: 0;
    width: 100%;
    text-align: center;
  }


  #home-slider .slider-hill {
    right: 50%;
    margin-right: -200px;
  }

  #home-slider .slider-house {
    right: 50%;
    margin-right: -125px;
  }

  #home-slider .slider-sun {
    right: 50%;
    margin-right: 60px;
  }

  #home-slider .slider-birds1 {
    width: 70px;
    right: 50%;
    margin-right: 120px;
  }

  #home-slider .slider-birds2 {
    width: 70px;
    right: 50%;
    margin-right: -200px;
  }

  /*Pricing Table*/
  .single-price, .single-table {
    margin-bottom: 45px;
  }

  .single-price .plan-price {
    height: 200px;
    padding-top: 25px;
  }

  .single-price .plan-price:after {
    border-width: 200px;
    margin-left: -200px;
  }

  .single-table:before {
    border-width: 0 225px 60px;
  }

  .single-table .btn-signup:after {
    border-width: 78px 225px 0;
  }

  .single-table .btn-signup a {
    padding: 15px;
  }


  #action.responsive{
    height: 255px;
  }

  .action.take-tour{
    margin-top: -95px;
  }

  .action.take-tour .col-sm-7{
    width: 100%;
    text-align: center;
  }

  .action.take-tour .col-sm-5{
    width: 100%;
    margin-top: 120px;
  }

  .single-features {
    padding-bottom: 0;
    padding-top: 30px;
  }

  .single-features img {
    margin-bottom: 25px;
  }


  .single-features {
    text-align: center;
  }

  .single-features * {
    display: inline-block;
    text-align: center;
  }


  /* about us */

  .team-single {
    margin-bottom: 30px;
  }

  #company-information.choose .padding-top {
    padding-top: 0;
  }

  /* Blog */
  .arrow-right:after {
    background: none ;
  }

  .arrow-left:before {
    background: none;
  }

  .col-sm-6.padding-right.arrow-right{
    padding-right: 15px;
  }

  .col-sm-6.padding-left.padding-top.arrow-left {
    margin-bottom: 70px;
    padding-left: 15px;
  }

  .blog-padding-right {
    padding-right: 15px;
  }

  .nav.nav-justified.post-nav li, 
  .nav.navbar-nav.post-nav li{
    display: inline-block;
    margin-right: 15px;
  }

  .author-profile .col-sm-2 img {
    margin-bottom: 20px;
    width: auto;
  }

  .post-comment {
    padding-left: 0;
  }

  .parrent .post-comment {
    margin-left: 0;
  }

  .parrent .media-list {
    margin-left: 140px;
  }

  .post-comment .pull-left img{
    margin-right: 10px;
  }

  .single-blog.two-column .post-overlay{
    margin-top: 0;
  }

  /* Portfolio */

  .project-name h2 {
    margin-top: 20px;
  }

  .portfolio-filter li a{
    padding: 0 15px;
  }

  /*Coming Soon CSS*/
  #countdown li.angle-one {
    left: 60px;
  }

  #countdown li.angle-two {
    left: 20px;
  }

  #countdown li.angle-three {
    right: 20px;
  }

  #countdown li.angle-four {
    right: 60px;
  }

  #countdown li > span {
    font-size: 36px;
    line-height: 36px;
    margin-top: 30px;
  }

  #countdown li.angle-two > span,
  #countdown li.angle-four > span {
    margin-top: 60px;
  }

  #subscribe {
    font-size: 14px;
    text-align: center;
  }

  #newsletter .form-control {
    font-size: 14px;
    height: 40px
  }

  #newsletter i {
    font-size: 25px;
  }

  #countdown li.angle-one span.minutes, 
  #countdown li.angle-one .minute {
    margin-left: 0
  }

  /* Contact-us2 CSS*/
  #map-section .contact-info {
    width: 51%;
    padding: 20px;
  }

  #gmap {
    height: 310px;
  }

  #contact-form .btn-submit {
    width:50%;
  }

  #map-section .contact-info h2 {
    font-size: 20px;
  }

  #map-section .contact-info address {
    font-size: 14px;
  }


  /* 404 error CSS*/
  #error-page {
    padding: 50px 25px;
  }

  #error-page h2 {
    font-size: 24px;
  }

  .btn-error {
    font-size: 13px;
    padding: 12px 18px;
  }
}

@media only screen and (max-width : 480px) {

  /* Pricing Table*/

  .single-price, .single-table {
    margin-bottom: 35px;
  }

  .single-price .plan-price {
    height: 110px;
    padding-top: 7px;
  }

  .single-price .plan-price:after {
    border-width: 110px;
    margin-left: -110px;
  }

  .single-table:before {
    border-width: 0 145px 60px;
  }

  .single-table .btn-signup:after {
    border-width: 78px 145px 0;
  }

  /* Coming soon */
  #countdown li.angle-one {
    left: 25px;
  }

  #countdown li.angle-two {
    left: 10px;
  }

  #countdown li.angle-three {
    right: 10px;
  }

  #countdown li.angle-four {
    right: 25px;
  }

  #countdown li > span {
    font-size: 14px;
    line-height: 14px;
    margin-top: 10px;
  }

  #countdown li.angle-two > span,
  #countdown li.angle-four > span {
    margin-top: 30px;
  }


  #subscribe {
    font-size: 14px;
    text-align: center;
  }

  #newsletter .form-control {
    font-size: 14px;
    height: 40px
  }

  #newsletter i {
    font-size: 25px;
  }

  #countdown li.angle-one span.minutes, 
  #countdown li.angle-one .minute {
    margin-left: 0
  }

  /* Contact-us2 CSS*/

  #map-section .contact-info {
    width: 100%;
  }

  #gmap {
    height: 390px;
  }

  #contact-form .btn-submit {
    width: 60%;
  }

  /* 404 error CSS*/

  #error-page {
    padding: 50px 25px;
  }

  #error-page h2 {
    font-size: 24px;
  }

  .btn-error {
    font-size: 10px;
    padding: 12px 11px;
  }
}